// stylelint-disable plugin/selector-bem-pattern, scss/media-feature-value-dollar-variable

.aux-lockdown {
  max-width: 450px;
  margin: 40px auto;
  border-radius: 2px;

  margin-top: 20vh;

  .aux-lockdown--wrap {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  .aux-lockdown--wrap-outer {
    display: table-cell;
    vertical-align: top;
    padding: 20px;
    padding-right: 10px;

    ~ .aux-lockdown--wrap-outer {
      padding-left: 10px;
    }
  }

  .aux-lockdown--logosection {
    width: 30%;
    text-align: center;

    a {
      display: block;
    }

    img {
      max-width: 100%;
    }
  }

  .aux-lockdown--logo {
    height: 83px;
  }

  .aux-lockdown--content {
    width: 70%;
  }

  .aux-lockdown--title {
    font-size: 22px;
    font-weight: 700;
    line-height: 1.2;

    margin-bottom: 16px;
  }

  .aux-lockdown--description {
    line-height: 1.5;

    margin-bottom: 16px;
  }

  .aux-lockdown--field {
    position: relative;
  }

  label {
    display: none;
  }

  input {
    display: inline-block;
    padding: 11px 24px 11px 0;

    font-size: 16px;

    border: none;
    outline: none;
    appearance: none;

    transition: border 200ms ease, background-color 200ms ease;

    &:focus, &:hover {
      border: none;
    }
  }

  input[type='text'],
  input[type='password'] {
    border-bottom: 2px solid #111111;
    border-radius: 0;

    width: 100%;
  }

  .aux-lockdown--submit,
  button[type='submit'] {
    appearance: none;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    position: absolute;
    top: 50%;
    right: 0;

    width: 17px;
    height: 15px;

    transform: translateY(-50%);

    &:focus, &:hover {
      .arrow {
        visibility: hidden;

        &.arrow__hover {
          visibility: visible;
        }
      }
    }
  }

  ul.errorlist { // stylelint-disable selector-no-qualifying-type
    margin-top: 12px;
    color: #dc0046;
    text-align: left;
  }

  .arrow {
    visibility: visible;

    position: absolute;
    left: 0;
    top: 0;

    &.arrow__hover {
      visibility: hidden;
    }
  }
}


@media (max-width: 767px) {
  .aux-lockdown {
    text-align: center;
    padding: 0 30px;
    margin-top: 20vh;

    .aux-lockdown--wrap {
      display: block;
    }

    .aux-lockdown--wrap-outer {
      display: block;
      width: 100%;
    }

    .aux-lockdown--logosection {
      padding: 0 0 15px;
    }

    .aux-lockdown--logo {
      width: 60px;
      height: 60px;
    }

    .aux-lockdown--description {
      margin-bottom: 50px;
    }
  }
}
